var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _toInteger from "lodash/toInteger";
import _merge from "lodash/merge";
import { globalHistory } from "@reach/router";
import moment from "moment";
import { isSpecialEmploymentStatus } from "../../utils";
export function saveQuoteInfoMapper(state, fullFormState, store) { return _merge({ premium: state.amount.toString(), discountCode: state.promo_code, planFlag: state.planFlag, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }); }
export function saveSt1QuoteInfoMapper(state, fullFormState, store) { return _merge({ premium: state.amount.toString(), discountCode: state.promo_code, planFlag: state.plan_flag, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }); }
export function savePa1QuoteInfoMapper(state, fullFormState, store) {
    var _JSON$parse$value$toS, _JSON$parse, _JSON$parse$value$toS2, _JSON$parse2, _JSON$parse$value$toS3, _JSON$parse3, _state$language, _state$amount, _state$period, _state$pay_interval, _state$promo_code;
    var employmentStatus = JSON.parse(JSON.stringify(state.employment_status)).value;
    var jobPosition = (_JSON$parse$value$toS = (_JSON$parse = JSON.parse(JSON.stringify(state.job_position))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.value.toString()) !== null && _JSON$parse$value$toS !== void 0 ? _JSON$parse$value$toS : null;
    var occupationClass = (_JSON$parse$value$toS2 = (_JSON$parse2 = JSON.parse(JSON.stringify(state.occupation_code))) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.value.toString()) !== null && _JSON$parse$value$toS2 !== void 0 ? _JSON$parse$value$toS2 : null;
    var industryCode = (_JSON$parse$value$toS3 = (_JSON$parse3 = JSON.parse(JSON.stringify(state.industry))) === null || _JSON$parse3 === void 0 ? void 0 : _JSON$parse3.value.toString()) !== null && _JSON$parse$value$toS3 !== void 0 ? _JSON$parse$value$toS3 : null;
    if (isSpecialEmploymentStatus(employmentStatus)) {
        jobPosition = null;
        industryCode = null;
        occupationClass = null;
    }
    return _merge({ relationship: "00", language: (_state$language = state === null || state === void 0 ? void 0 : state.language) !== null && _state$language !== void 0 ? _state$language : "", premium: (_state$amount = state.amount) === null || _state$amount === void 0 ? void 0 : _state$amount.toString(), period: (_state$period = state.period) !== null && _state$period !== void 0 ? _state$period : 1, payInterval: (_state$pay_interval = state.pay_interval) !== null && _state$pay_interval !== void 0 ? _state$pay_interval : "12", discountCode: (_state$promo_code = state.promo_code) !== null && _state$promo_code !== void 0 ? _state$promo_code : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid,
        employmentStatus: employmentStatus, industryCode: industryCode, jobPosition: jobPosition, occupationClass: occupationClass });
}
export function saveMicroSavingsQuoteInfoMapper(state, fullFormState, store) {
    return _merge({ premium: state.amount.toString(), discountCode: state.promo_code,
        planFlag: state.pay_interval, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, payInterval: state.pay_interval });
}
export function saveTF1QuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl, _moment$diff, _state$quote_card_tl2, _state$quote_card_tl3, _state$quote_card_tl4, _state$til_sixty_five, _state$quote_card_tl5, _moment$format, _state$quote_card_tl6, _state$promo_code2;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl = state.quote_card_tl) === null || _state$quote_card_tl === void 0 ? void 0 : _state$quote_card_tl.smoke; // === "1" ? "Y" : "N";
    var age = (_moment$diff = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl2 = state.quote_card_tl) === null || _state$quote_card_tl2 === void 0 ? void 0 : _state$quote_card_tl2.date_of_birth), "years")) !== null && _moment$diff !== void 0 ? _moment$diff : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl3 = state.quote_card_tl) === null || _state$quote_card_tl3 === void 0 ? void 0 : _state$quote_card_tl3.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl4 = state.quote_card_tl) === null || _state$quote_card_tl4 === void 0 ? void 0 : _state$quote_card_tl4.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, til65: (_state$til_sixty_five = state.til_sixty_five) !== null && _state$til_sixty_five !== void 0 ? _state$til_sixty_five : undefined, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl5 = state.quote_card_tl) === null || _state$quote_card_tl5 === void 0 ? void 0 : _state$quote_card_tl5.sex, dateOfBirth: (_moment$format = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl6 = state.quote_card_tl) === null || _state$quote_card_tl6 === void 0 ? void 0 : _state$quote_card_tl6.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format !== void 0 ? _moment$format : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code2 = state.promo_code) !== null && _state$promo_code2 !== void 0 ? _state$promo_code2 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest, healthLoading: store === null || store === void 0 ? void 0 : store.commonStates.loading // planFlag: state.planFlag,
    });
    return req;
}
export function planSelectTF1PageReverseMapper(state) {
    var _parsedQuery$get, _state$geQuoteMain, _state$geQuoteMain2, _state$geQuoteMain3, _state$geQuoteMain4, _state$geQuoteMain5, _state$geQuoteMain$ti, _state$geQuoteMain6, _state$geQuoteMain7, _party$gender, _state$geQuoteMain$di, _state$geQuoteMain8;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get = parsedQuery.get("flag")) !== null && _parsedQuery$get !== void 0 ? _parsedQuery$get : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", button_health_report: { 1: null, 2: null, 3: null }, pay_interval: "12" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain = state.geQuoteMain) === null || _state$geQuoteMain === void 0 ? void 0 : _state$geQuoteMain.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain2 = state.geQuoteMain) === null || _state$geQuoteMain2 === void 0 ? void 0 : _state$geQuoteMain2.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain3 = state.geQuoteMain) !== null && _state$geQuoteMain3 !== void 0 && _state$geQuoteMain3.period && !(state !== null && state !== void 0 && (_state$geQuoteMain4 = state.geQuoteMain) !== null && _state$geQuoteMain4 !== void 0 && _state$geQuoteMain4.til65) ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain5 = state.geQuoteMain) === null || _state$geQuoteMain5 === void 0 ? void 0 : _state$geQuoteMain5.period) : 1, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", language: "",
        til_sixty_five: (_state$geQuoteMain$ti = state === null || state === void 0 ? void 0 : (_state$geQuoteMain6 = state.geQuoteMain) === null || _state$geQuoteMain6 === void 0 ? void 0 : _state$geQuoteMain6.til65) !== null && _state$geQuoteMain$ti !== void 0 ? _state$geQuoteMain$ti : false, button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain7 = state.geQuoteMain) === null || _state$geQuoteMain7 === void 0 ? void 0 : _state$geQuoteMain7.payInterval,
        quote_card_tl: { sex: (_party$gender = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender !== void 0 ? _party$gender : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di = state === null || state === void 0 ? void 0 : (_state$geQuoteMain8 = state.geQuoteMain) === null || _state$geQuoteMain8 === void 0 ? void 0 : _state$geQuoteMain8.discountCode) !== null && _state$geQuoteMain$di !== void 0 ? _state$geQuoteMain$di : "" }; //QBR-1563
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { res.button_health_report[q.quetioncode] = q.selectoption; });
    return res;
}
export function saveTLQuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl7, _moment$diff2, _state$quote_card_tl8, _state$quote_card_tl9, _state$quote_card_tl10, _state$til_sixty_five2, _state$quote_card_tl11, _moment$format2, _state$quote_card_tl12, _state$promo_code3;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl7 = state.quote_card_tl) === null || _state$quote_card_tl7 === void 0 ? void 0 : _state$quote_card_tl7.smoke; // === "1" ? "Y" : "N";
    var age = (_moment$diff2 = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl8 = state.quote_card_tl) === null || _state$quote_card_tl8 === void 0 ? void 0 : _state$quote_card_tl8.date_of_birth), "years")) !== null && _moment$diff2 !== void 0 ? _moment$diff2 : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl9 = state.quote_card_tl) === null || _state$quote_card_tl9 === void 0 ? void 0 : _state$quote_card_tl9.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl10 = state.quote_card_tl) === null || _state$quote_card_tl10 === void 0 ? void 0 : _state$quote_card_tl10.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, til65: (_state$til_sixty_five2 = state.til_sixty_five) !== null && _state$til_sixty_five2 !== void 0 ? _state$til_sixty_five2 : undefined, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl11 = state.quote_card_tl) === null || _state$quote_card_tl11 === void 0 ? void 0 : _state$quote_card_tl11.sex, dateOfBirth: (_moment$format2 = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl12 = state.quote_card_tl) === null || _state$quote_card_tl12 === void 0 ? void 0 : _state$quote_card_tl12.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format2 !== void 0 ? _moment$format2 : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code3 = state.promo_code) !== null && _state$promo_code3 !== void 0 ? _state$promo_code3 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest // planFlag: state.planFlag,
    });
    return req;
}
export function planSelectTLPageReverseMapper(state) {
    var _state$geQuoteMain9, _state$geQuoteMain10, _state$geQuoteMain11, _state$geQuoteMain12, _state$geQuoteMain13, _state$geQuoteMain$ti2, _state$geQuoteMain14, _state$geQuoteMain15, _party$gender2, _state$geQuoteMain$di2, _state$geQuoteMain16;
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain9 = state.geQuoteMain) === null || _state$geQuoteMain9 === void 0 ? void 0 : _state$geQuoteMain9.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain10 = state.geQuoteMain) === null || _state$geQuoteMain10 === void 0 ? void 0 : _state$geQuoteMain10.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain11 = state.geQuoteMain) !== null && _state$geQuoteMain11 !== void 0 && _state$geQuoteMain11.period && !(state !== null && state !== void 0 && (_state$geQuoteMain12 = state.geQuoteMain) !== null && _state$geQuoteMain12 !== void 0 && _state$geQuoteMain12.til65) ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain13 = state.geQuoteMain) === null || _state$geQuoteMain13 === void 0 ? void 0 : _state$geQuoteMain13.period) : 1, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", language: "",
        til_sixty_five: (_state$geQuoteMain$ti2 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain14 = state.geQuoteMain) === null || _state$geQuoteMain14 === void 0 ? void 0 : _state$geQuoteMain14.til65) !== null && _state$geQuoteMain$ti2 !== void 0 ? _state$geQuoteMain$ti2 : false, button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain15 = state.geQuoteMain) === null || _state$geQuoteMain15 === void 0 ? void 0 : _state$geQuoteMain15.payInterval,
        quote_card_tl: { sex: (_party$gender2 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender2 !== void 0 ? _party$gender2 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di2 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain16 = state.geQuoteMain) === null || _state$geQuoteMain16 === void 0 ? void 0 : _state$geQuoteMain16.discountCode) !== null && _state$geQuoteMain$di2 !== void 0 ? _state$geQuoteMain$di2 : "" }; //QBR-1563
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { res.button_health_report[q.quetioncode] = q.selectoption; });
    return res;
}
export function saveTL2QuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl13, _moment$diff3, _state$quote_card_tl14, _state$quote_card_tl15, _state$quote_card_tl16, _state$quote_card_tl17, _moment$format3, _state$quote_card_tl18, _state$promo_code4;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl13 = state.quote_card_tl) === null || _state$quote_card_tl13 === void 0 ? void 0 : _state$quote_card_tl13.smoke; //=== "1" ? "Y" : "N";
    var age = (_moment$diff3 = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl14 = state.quote_card_tl) === null || _state$quote_card_tl14 === void 0 ? void 0 : _state$quote_card_tl14.date_of_birth), "years")) !== null && _moment$diff3 !== void 0 ? _moment$diff3 : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl15 = state.quote_card_tl) === null || _state$quote_card_tl15 === void 0 ? void 0 : _state$quote_card_tl15.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl16 = state.quote_card_tl) === null || _state$quote_card_tl16 === void 0 ? void 0 : _state$quote_card_tl16.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl17 = state.quote_card_tl) === null || _state$quote_card_tl17 === void 0 ? void 0 : _state$quote_card_tl17.sex, dateOfBirth: (_moment$format3 = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl18 = state.quote_card_tl) === null || _state$quote_card_tl18 === void 0 ? void 0 : _state$quote_card_tl18.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format3 !== void 0 ? _moment$format3 : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code4 = state.promo_code) !== null && _state$promo_code4 !== void 0 ? _state$promo_code4 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest // planFlag: state.planFlag,
    });
    return req;
}
export function planSelectTL2PageReverseMapper(state) {
    var _state$geQuoteMain17, _state$geQuoteMain18, _state$geQuoteMain19, _state$geQuoteMain20, _state$geQuoteMain21, _party$gender3, _state$geQuoteMain$di3, _state$geQuoteMain22;
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain17 = state.geQuoteMain) === null || _state$geQuoteMain17 === void 0 ? void 0 : _state$geQuoteMain17.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain18 = state.geQuoteMain) === null || _state$geQuoteMain18 === void 0 ? void 0 : _state$geQuoteMain18.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain19 = state.geQuoteMain) !== null && _state$geQuoteMain19 !== void 0 && _state$geQuoteMain19.period ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain20 = state.geQuoteMain) === null || _state$geQuoteMain20 === void 0 ? void 0 : _state$geQuoteMain20.period) : 5, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", language: "", button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain21 = state.geQuoteMain) === null || _state$geQuoteMain21 === void 0 ? void 0 : _state$geQuoteMain21.payInterval,
        quote_card_tl: { sex: (_party$gender3 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender3 !== void 0 ? _party$gender3 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di3 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain22 = state.geQuoteMain) === null || _state$geQuoteMain22 === void 0 ? void 0 : _state$geQuoteMain22.discountCode) !== null && _state$geQuoteMain$di3 !== void 0 ? _state$geQuoteMain$di3 : "" };
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { res.button_health_report[q.quetioncode] = q.selectoption; }); //QBR-1563
    return res;
}
export function saveTL3QuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl19, _moment$diff4, _state$quote_card_tl20, _state$quote_card_tl21, _state$quote_card_tl22, _state$til_sixty_five3, _state$quote_card_tl23, _moment$format4, _state$quote_card_tl24, _state$promo_code5, _state$planFlag;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl19 = state.quote_card_tl) === null || _state$quote_card_tl19 === void 0 ? void 0 : _state$quote_card_tl19.smoke; //=== "1" ? "Y" : "N";
    var age = (_moment$diff4 = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl20 = state.quote_card_tl) === null || _state$quote_card_tl20 === void 0 ? void 0 : _state$quote_card_tl20.date_of_birth), "years")) !== null && _moment$diff4 !== void 0 ? _moment$diff4 : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl21 = state.quote_card_tl) === null || _state$quote_card_tl21 === void 0 ? void 0 : _state$quote_card_tl21.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl22 = state.quote_card_tl) === null || _state$quote_card_tl22 === void 0 ? void 0 : _state$quote_card_tl22.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, til65: (_state$til_sixty_five3 = state.til_sixty_five) !== null && _state$til_sixty_five3 !== void 0 ? _state$til_sixty_five3 : undefined, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl23 = state.quote_card_tl) === null || _state$quote_card_tl23 === void 0 ? void 0 : _state$quote_card_tl23.sex, dateOfBirth: (_moment$format4 = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl24 = state.quote_card_tl) === null || _state$quote_card_tl24 === void 0 ? void 0 : _state$quote_card_tl24.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format4 !== void 0 ? _moment$format4 : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code5 = state.promo_code) !== null && _state$promo_code5 !== void 0 ? _state$promo_code5 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest, healthLoading: store === null || store === void 0 ? void 0 : store.commonStates.loading, planFlag: (_state$planFlag = state.planFlag) !== null && _state$planFlag !== void 0 ? _state$planFlag : "S" });
    return req;
}
export function planSelectTL3PageReverseMapper(state) {
    var _parsedQuery$get2, _state$geQuoteMain23, _state$geQuoteMain24, _state$geQuoteMain25, _state$geQuoteMain26, _state$geQuoteMain27, _state$geQuoteMain$ti3, _state$geQuoteMain28, _state$geQuoteMain29, _party$gender4, _state$geQuoteMain$di4, _state$geQuoteMain30;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get2 = parsedQuery.get("flag")) !== null && _parsedQuery$get2 !== void 0 ? _parsedQuery$get2 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", button_health_report: { 1: null, 2: null, 3: null }, pay_interval: "12" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain23 = state.geQuoteMain) === null || _state$geQuoteMain23 === void 0 ? void 0 : _state$geQuoteMain23.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain24 = state.geQuoteMain) === null || _state$geQuoteMain24 === void 0 ? void 0 : _state$geQuoteMain24.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain25 = state.geQuoteMain) !== null && _state$geQuoteMain25 !== void 0 && _state$geQuoteMain25.period && !(state !== null && state !== void 0 && (_state$geQuoteMain26 = state.geQuoteMain) !== null && _state$geQuoteMain26 !== void 0 && _state$geQuoteMain26.til65) ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain27 = state.geQuoteMain) === null || _state$geQuoteMain27 === void 0 ? void 0 : _state$geQuoteMain27.period) : 5, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", healthLoading: 0, language: "", til_sixty_five: (_state$geQuoteMain$ti3 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain28 = state.geQuoteMain) === null || _state$geQuoteMain28 === void 0 ? void 0 : _state$geQuoteMain28.til65) !== null && _state$geQuoteMain$ti3 !== void 0 ? _state$geQuoteMain$ti3 : false, button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain29 = state.geQuoteMain) === null || _state$geQuoteMain29 === void 0 ? void 0 : _state$geQuoteMain29.payInterval,
        quote_card_tl: { sex: (_party$gender4 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender4 !== void 0 ? _party$gender4 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di4 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain30 = state.geQuoteMain) === null || _state$geQuoteMain30 === void 0 ? void 0 : _state$geQuoteMain30.discountCode) !== null && _state$geQuoteMain$di4 !== void 0 ? _state$geQuoteMain$di4 : "" };
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { res.button_health_report[q.quetioncode] = q.selectoption; }); //QBR-1563
    return res;
}
export function save3in1QuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl25, _moment$diff5, _state$quote_card_tl26, _state$quote_card_tl27, _state$quote_card_tl28, _state$quote_card_tl29, _moment$format5, _state$quote_card_tl30, _state$promo_code6;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl25 = state.quote_card_tl) === null || _state$quote_card_tl25 === void 0 ? void 0 : _state$quote_card_tl25.smoke; //=== "1" ? "Y" : "N";
    var age = (_moment$diff5 = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl26 = state.quote_card_tl) === null || _state$quote_card_tl26 === void 0 ? void 0 : _state$quote_card_tl26.date_of_birth), "years")) !== null && _moment$diff5 !== void 0 ? _moment$diff5 : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl27 = state.quote_card_tl) === null || _state$quote_card_tl27 === void 0 ? void 0 : _state$quote_card_tl27.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl28 = state.quote_card_tl) === null || _state$quote_card_tl28 === void 0 ? void 0 : _state$quote_card_tl28.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl29 = state.quote_card_tl) === null || _state$quote_card_tl29 === void 0 ? void 0 : _state$quote_card_tl29.sex, dateOfBirth: (_moment$format5 = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl30 = state.quote_card_tl) === null || _state$quote_card_tl30 === void 0 ? void 0 : _state$quote_card_tl30.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format5 !== void 0 ? _moment$format5 : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code6 = state.promo_code) !== null && _state$promo_code6 !== void 0 ? _state$promo_code6 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest });
    return req;
}
export function planSelect3in1PageReverseMapper(state) {
    var _parsedQuery$get3, _state$geQuoteMain31, _state$geQuoteMain32, _state$geQuoteMain33, _state$geQuoteMain34, _state$geQuoteMain35, _party$gender5, _state$geQuoteMain$di5, _state$geQuoteMain36;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get3 = parsedQuery.get("flag")) !== null && _parsedQuery$get3 !== void 0 ? _parsedQuery$get3 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", button_health_report: { 1: null, 2: null, 3: null }, pay_interval: "12" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain31 = state.geQuoteMain) === null || _state$geQuoteMain31 === void 0 ? void 0 : _state$geQuoteMain31.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain32 = state.geQuoteMain) === null || _state$geQuoteMain32 === void 0 ? void 0 : _state$geQuoteMain32.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain33 = state.geQuoteMain) !== null && _state$geQuoteMain33 !== void 0 && _state$geQuoteMain33.period ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain34 = state.geQuoteMain) === null || _state$geQuoteMain34 === void 0 ? void 0 : _state$geQuoteMain34.period) : 5, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", language: "", button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain35 = state.geQuoteMain) === null || _state$geQuoteMain35 === void 0 ? void 0 : _state$geQuoteMain35.payInterval,
        quote_card_tl: { sex: (_party$gender5 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender5 !== void 0 ? _party$gender5 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di5 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain36 = state.geQuoteMain) === null || _state$geQuoteMain36 === void 0 ? void 0 : _state$geQuoteMain36.discountCode) !== null && _state$geQuoteMain$di5 !== void 0 ? _state$geQuoteMain$di5 : "" };
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { if (q.selectoption) {
        res.button_health_report[q.quetioncode] = q.selectoption;
    } }); //QBR-1563
    return res;
}
export function planSelectCi1PageReverseMapper(state) {
    var _parsedQuery$get4, _state$geQuoteMain37, _state$geQuoteMain38, _state$geQuoteMain39, _state$geQuoteMain40, _state$geQuoteMain41, _party$gender6, _state$geQuoteMain$di6, _state$geQuoteMain42;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get4 = parsedQuery.get("flag")) !== null && _parsedQuery$get4 !== void 0 ? _parsedQuery$get4 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", button_health_report: { 1: null, 2: null, 3: null }, pay_interval: "12" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if ((party === null || party === void 0 ? void 0 : party.partyHeight) === 0 && (party === null || party === void 0 ? void 0 : party.partyWeight) === 0 && !party.smoke && !party.partyEName && !party.partyCName) {
        return null;
    } //QBR-1534
    var res = { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain37 = state.geQuoteMain) === null || _state$geQuoteMain37 === void 0 ? void 0 : _state$geQuoteMain37.amount), planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain38 = state.geQuoteMain) === null || _state$geQuoteMain38 === void 0 ? void 0 : _state$geQuoteMain38.planFlag, period: state !== null && state !== void 0 && (_state$geQuoteMain39 = state.geQuoteMain) !== null && _state$geQuoteMain39 !== void 0 && _state$geQuoteMain39.period ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain40 = state.geQuoteMain) === null || _state$geQuoteMain40 === void 0 ? void 0 : _state$geQuoteMain40.period) : 5, age: party === null || party === void 0 ? void 0 : party.age, relationship: "00", language: "", button_health_report: {}, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain41 = state.geQuoteMain) === null || _state$geQuoteMain41 === void 0 ? void 0 : _state$geQuoteMain41.payInterval,
        quote_card_tl: { sex: (_party$gender6 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender6 !== void 0 ? _party$gender6 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di6 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain42 = state.geQuoteMain) === null || _state$geQuoteMain42 === void 0 ? void 0 : _state$geQuoteMain42.discountCode) !== null && _state$geQuoteMain$di6 !== void 0 ? _state$geQuoteMain$di6 : "" };
    var hq = state === null || state === void 0 ? void 0 : state.geQuoteUwquestionList.filter(function (q) { return q.answertype === "01"; });
    hq.map(function (q) { if (q.selectoption) {
        res.button_health_report[q.quetioncode] = q.selectoption;
    } }); //QBR-1563
    return res;
}
export function planSelectPageReverseMapper(state) {
    var _parsedQuery$get5, _state$geQuoteMain43, _state$geQuoteMain44, _state$geQuoteMain45;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get5 = parsedQuery.get("flag")) !== null && _parsedQuery$get5 !== void 0 ? _parsedQuery$get5 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    return { amount: state === null || state === void 0 ? void 0 : (_state$geQuoteMain43 = state.geQuoteMain) === null || _state$geQuoteMain43 === void 0 ? void 0 : _state$geQuoteMain43.premium, promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain44 = state.geQuoteMain) === null || _state$geQuoteMain44 === void 0 ? void 0 : _state$geQuoteMain44.discountCode, planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain45 = state.geQuoteMain) === null || _state$geQuoteMain45 === void 0 ? void 0 : _state$geQuoteMain45.planFlag };
}
export function planSelectE1PageReverseMapper(state) {
    var _parsedQuery$get6, _state$geQuoteMain46, _state$geQuoteMain47, _state$geQuoteMain48;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get6 = parsedQuery.get("flag")) !== null && _parsedQuery$get6 !== void 0 ? _parsedQuery$get6 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    return { ageConfirm: true, amount: state === null || state === void 0 ? void 0 : (_state$geQuoteMain46 = state.geQuoteMain) === null || _state$geQuoteMain46 === void 0 ? void 0 : _state$geQuoteMain46.premium, promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain47 = state.geQuoteMain) === null || _state$geQuoteMain47 === void 0 ? void 0 : _state$geQuoteMain47.discountCode, planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain48 = state.geQuoteMain) === null || _state$geQuoteMain48 === void 0 ? void 0 : _state$geQuoteMain48.planFlag };
}
export function planSelectSt1PageReverseMapper(state) {
    var _parsedQuery$get7, _state$geQuoteMain49, _state$geQuoteMain50, _state$geQuoteMain51;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get7 = parsedQuery.get("flag")) !== null && _parsedQuery$get7 !== void 0 ? _parsedQuery$get7 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", plan_flag: "2" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    return { ageConfirm: true, amount: state === null || state === void 0 ? void 0 : (_state$geQuoteMain49 = state.geQuoteMain) === null || _state$geQuoteMain49 === void 0 ? void 0 : _state$geQuoteMain49.premium, promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain50 = state.geQuoteMain) === null || _state$geQuoteMain50 === void 0 ? void 0 : _state$geQuoteMain50.discountCode, plan_flag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain51 = state.geQuoteMain) === null || _state$geQuoteMain51 === void 0 ? void 0 : _state$geQuoteMain51.planFlag // handle 1 or 2 year plan
    };
}
export function planSelectMicroSavingsPageReverseMapper(state) {
    var _parsedQuery$get8, _state$geQuoteMain52, _state$geQuoteMain53, _state$geQuoteMain54, _state$geQuoteMain55;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get8 = parsedQuery.get("flag")) !== null && _parsedQuery$get8 !== void 0 ? _parsedQuery$get8 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", pay_interval: "1" };
    }
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    return { ageConfirm: true, amount: state === null || state === void 0 ? void 0 : (_state$geQuoteMain52 = state.geQuoteMain) === null || _state$geQuoteMain52 === void 0 ? void 0 : _state$geQuoteMain52.premium, promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain53 = state.geQuoteMain) === null || _state$geQuoteMain53 === void 0 ? void 0 : _state$geQuoteMain53.discountCode,
        planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain54 = state.geQuoteMain) === null || _state$geQuoteMain54 === void 0 ? void 0 : _state$geQuoteMain54.payInterval, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain55 = state.geQuoteMain) === null || _state$geQuoteMain55 === void 0 ? void 0 : _state$geQuoteMain55.payInterval };
}
export function saveCi1QuoteInfoMapper(state, fullFormState, store) {
    var _state$quote_card_tl31, _moment$diff6, _state$quote_card_tl32, _state$quote_card_tl33, _state$quote_card_tl34, _state$quote_card_tl35, _moment$format6, _state$quote_card_tl36, _state$promo_code7;
    if (!state.button_health_report) {
        return;
    }
    var quest = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteUwquestionList.map(function (q) { return { quetioncode: q.quetioncode, answertype: q.answertype, selectoption: q.selectoption, answertext: q.answertext }; });
    var isSmoke = state === null || state === void 0 ? void 0 : (_state$quote_card_tl31 = state.quote_card_tl) === null || _state$quote_card_tl31 === void 0 ? void 0 : _state$quote_card_tl31.smoke; //=== "1" ? "Y" : "N";
    var age = (_moment$diff6 = moment().diff(moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl32 = state.quote_card_tl) === null || _state$quote_card_tl32 === void 0 ? void 0 : _state$quote_card_tl32.date_of_birth), "years")) !== null && _moment$diff6 !== void 0 ? _moment$diff6 : 18;
    var req = _merge({ height: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl33 = state.quote_card_tl) === null || _state$quote_card_tl33 === void 0 ? void 0 : _state$quote_card_tl33.height), weight: Number(state === null || state === void 0 ? void 0 : (_state$quote_card_tl34 = state.quote_card_tl) === null || _state$quote_card_tl34 === void 0 ? void 0 : _state$quote_card_tl34.weight), relationship: "00", language: state === null || state === void 0 ? void 0 : state.language, age: age, isSmoker: isSmoke, gender: state === null || state === void 0 ? void 0 : (_state$quote_card_tl35 = state.quote_card_tl) === null || _state$quote_card_tl35 === void 0 ? void 0 : _state$quote_card_tl35.sex, dateOfBirth: (_moment$format6 = moment(state === null || state === void 0 ? void 0 : (_state$quote_card_tl36 = state.quote_card_tl) === null || _state$quote_card_tl36 === void 0 ? void 0 : _state$quote_card_tl36.date_of_birth).format("YYYY-MM-DD")) !== null && _moment$format6 !== void 0 ? _moment$format6 : "", premium: _toInteger(state.amount).toString(), period: state.period, payInterval: state.pay_interval, discountCode: (_state$promo_code7 = state.promo_code) !== null && _state$promo_code7 !== void 0 ? _state$promo_code7 : "", productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, uwQuestionList: quest });
    return req;
}
export function planSelectPa1PageReverseMapper(state) {
    var _parsedQuery$get9, _state$geQuoteMain56, _state$geQuoteMain57, _state$geQuoteMain58, _party$age, _party$gender7, _state$geQuoteMain$di7, _state$geQuoteMain59, _state$geQuoteParties, _state$geQuoteParties2, _state$geQuoteParties3, _state$geQuoteParties4; // if (!state?.geQuoteMain.premium) {
    //   console.log("debug mapper planSelectPa1PageReverseMapper in");
    //   return null;
    // }
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get9 = parsedQuery.get("flag")) !== null && _parsedQuery$get9 !== void 0 ? _parsedQuery$get9 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { amount: 0, promo_code: "", employment_status: "3", pay_interval: "12" };
    }
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "1"; });
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium)) {
        return null;
    }
    if (!(state !== null && state !== void 0 && state.geQuoteParties[0].employmentStatus)) {
        return null;
    }
    return { amount: Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain56 = state.geQuoteMain) === null || _state$geQuoteMain56 === void 0 ? void 0 : _state$geQuoteMain56.amount), period: state !== null && state !== void 0 && (_state$geQuoteMain57 = state.geQuoteMain) !== null && _state$geQuoteMain57 !== void 0 && _state$geQuoteMain57.period ? Number(state === null || state === void 0 ? void 0 : (_state$geQuoteMain58 = state.geQuoteMain) === null || _state$geQuoteMain58 === void 0 ? void 0 : _state$geQuoteMain58.period) : 5, age: (_party$age = party === null || party === void 0 ? void 0 : party.age) !== null && _party$age !== void 0 ? _party$age : 30, relationship: "00", language: "", pay_interval: "12",
        quote_card_tl: { sex: (_party$gender7 = party === null || party === void 0 ? void 0 : party.gender) !== null && _party$gender7 !== void 0 ? _party$gender7 : undefined, smoke: party === null || party === void 0 ? void 0 : party.smoke,
            height: !(party !== null && party !== void 0 && party.partyHeight) || (party === null || party === void 0 ? void 0 : party.partyHeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyHeight.toString() : undefined, weight: !(party !== null && party !== void 0 && party.partyWeight) || (party === null || party === void 0 ? void 0 : party.partyWeight) > 0 ? party === null || party === void 0 ? void 0 : party.partyWeight.toString() : undefined, date_of_birth: party !== null && party !== void 0 && party.birthday ? new Date(party === null || party === void 0 ? void 0 : party.birthday) : undefined }, promo_code: (_state$geQuoteMain$di7 = state === null || state === void 0 ? void 0 : (_state$geQuoteMain59 = state.geQuoteMain) === null || _state$geQuoteMain59 === void 0 ? void 0 : _state$geQuoteMain59.discountCode) !== null && _state$geQuoteMain$di7 !== void 0 ? _state$geQuoteMain$di7 : "", ageConfirm: true,
        // employment_status: state?.geQuoteMain.employment_status?.value ?? "",
        // job_position: state?.geQuoteMain.employment_status?.value ?? "",
        // industry: state?.geQuoteMain.industry?.value ?? "",
        // occupation_code: state?.geQuoteMain.occupation_code?.value ?? "",
        employment_status: (_state$geQuoteParties = state === null || state === void 0 ? void 0 : state.geQuoteParties[0].employmentStatus) !== null && _state$geQuoteParties !== void 0 ? _state$geQuoteParties : "", job_position: (_state$geQuoteParties2 = state === null || state === void 0 ? void 0 : state.geQuoteParties[0].jobPosition) !== null && _state$geQuoteParties2 !== void 0 ? _state$geQuoteParties2 : "", industry: (_state$geQuoteParties3 = state === null || state === void 0 ? void 0 : state.geQuoteParties[0].industryCode) !== null && _state$geQuoteParties3 !== void 0 ? _state$geQuoteParties3 : "", occupation_code: (_state$geQuoteParties4 = state === null || state === void 0 ? void 0 : state.geQuoteParties[0].occupationCode) !== null && _state$geQuoteParties4 !== void 0 ? _state$geQuoteParties4 : "" };
}
export function saveOtcQuoteInfoMapper(state, fullFormState, store) { return _merge({ discountCode: state.promo_code, planFlag: state.plan_flag, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }); }
export function planSelectOtcPageReverseMapper(state) { var _state$geQuoteMain60, _state$geQuoteMain61, _state$geQuoteMain62; return { promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain60 = state.geQuoteMain) === null || _state$geQuoteMain60 === void 0 ? void 0 : _state$geQuoteMain60.discountCode, plan_flag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain61 = state.geQuoteMain) === null || _state$geQuoteMain61 === void 0 ? void 0 : _state$geQuoteMain61.planFlag, pay_interval: state === null || state === void 0 ? void 0 : (_state$geQuoteMain62 = state.geQuoteMain) === null || _state$geQuoteMain62 === void 0 ? void 0 : _state$geQuoteMain62.payInterval }; }
export function saveD1QuoteInfoMapper(state, fullFormState, store) { return _merge({ discountCode: state.promo_code, planFlag: state.plan_flag, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }); }
export function planSelectD1PageReverseMapper(state) {
    var _parsedQuery$get10, _state$geQuoteMain63, _state$geQuoteMain64;
    var query = globalHistory.location.search;
    var parsedQuery = new URLSearchParams(query);
    var flag = (_parsedQuery$get10 = parsedQuery.get("flag")) !== null && _parsedQuery$get10 !== void 0 ? _parsedQuery$get10 : ""; //繼續報價進來如果優惠碼過期，將直接進入step_2_intro頁面
    if (!(state !== null && state !== void 0 && state.geQuoteMain.premium) && flag) {
        return { plan_flag: "1" };
    }
    return { promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain63 = state.geQuoteMain) === null || _state$geQuoteMain63 === void 0 ? void 0 : _state$geQuoteMain63.discountCode, plan_flag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain64 = state.geQuoteMain) === null || _state$geQuoteMain64 === void 0 ? void 0 : _state$geQuoteMain64.planFlag };
} //D2
export function saveD2QuoteInfoMapper(state, fullFormState, store) { return _merge({ discountCode: state.promo_code, planFlag: state.plan_flag, productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }); }
export function planSelectD2PageReverseMapper(state) { var _state$geQuoteMain65, _state$geQuoteMain66; return { promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain65 = state.geQuoteMain) === null || _state$geQuoteMain65 === void 0 ? void 0 : _state$geQuoteMain65.discountCode, plan_flag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain66 = state.geQuoteMain) === null || _state$geQuoteMain66 === void 0 ? void 0 : _state$geQuoteMain66.planFlag }; }
export function saveM1QuoteInfoMapper(state, fullFormState, store) {
    var _state$apply_party_re;
    var apply_party = state.apply_party == "00" ? state.apply_party : (state === null || state === void 0 ? void 0 : (_state$apply_party_re = state.apply_party_relation) === null || _state$apply_party_re === void 0 ? void 0 : _state$apply_party_re.value) || (state === null || state === void 0 ? void 0 : state.apply_party_relation);
    return _merge({ discountCode: state.promo_code,
        productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, relationship: apply_party, insEmail: state === null || state === void 0 ? void 0 : state.email });
}
export function planSelectM1PageReverseMapper(state) {
    var _state$geQuoteMain67, _state$geQuoteMain68;
    var party = state === null || state === void 0 ? void 0 : state.geQuoteParties.find(function (e) { return e.partyFlag === "2"; });
    return { promo_code: state === null || state === void 0 ? void 0 : (_state$geQuoteMain67 = state.geQuoteMain) === null || _state$geQuoteMain67 === void 0 ? void 0 : _state$geQuoteMain67.discountCode,
        planFlag: state === null || state === void 0 ? void 0 : (_state$geQuoteMain68 = state.geQuoteMain) === null || _state$geQuoteMain68 === void 0 ? void 0 : _state$geQuoteMain68.payInterval,
        apply_party: party !== null && party !== void 0 && party.insRelationApp ? party.insRelationApp !== "00" ? "family" : party.insRelationApp : "00", apply_party_relation: (party === null || party === void 0 ? void 0 : party.insRelationApp) !== "00" ? party === null || party === void 0 ? void 0 : party.insRelationApp : "",
        email: party !== null && party !== void 0 && party.insRelationApp ? (party === null || party === void 0 ? void 0 : party.insRelationApp) !== "00" ? party === null || party === void 0 ? void 0 : party.email : "" : "" };
} // export function saveM2QuoteInfoMapper(
//   state: PlanSelectM2PageState,
//   fullFormState?: FormState,
//   store?: FullStore
// ) {
//   return merge({
//     discountCode: state.promo_code,
//     planFlag: state.plan_flag,
//     productCode: store?.qnbProduct.quoteInfo.geQuoteMain.eid,
//     payInterval: state?.pay_interval ?? "12",
//     relationship:
//       state.apply_party === "family"
//         ? state.apply_party_relation
//         : state.apply_party,
//   });
// }
// export function planSelectM2PageReverseMapper(
//   state: ApiGetQuoteInfoResult,
//   store?: FullStore
// ): PlanSelectM2PageState | null {
//   const party = state.geQuoteParties.find((i) => i.partyFlag === "2");
//   return {
//     promo_code: state?.geQuoteMain?.discountCode,
//     plan_flag: state?.geQuoteMain?.planFlag,
//     pay_interval: state?.geQuoteMain?.payInterval,
//     apply_party:
//       state?.geQuoteMain?.payInterval && party?.insRelationApp
//         ? party.insRelationApp !== "00"
//           ? "family"
//           : party.insRelationApp
//         : undefined,
//     apply_party_relation: party?.insRelationApp,
//   };
// }
export function saveOcpInfo1Mapper(state, fullFormState, store) { var _state$job_position, _state$industry, _state$occupation_cod; return { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, partyFlag: "1", jobPosition: (_state$job_position = state.job_position) === null || _state$job_position === void 0 ? void 0 : _state$job_position.value, industryCode: (_state$industry = state.industry) === null || _state$industry === void 0 ? void 0 : _state$industry.value, occupationCode: (_state$occupation_cod = state.occupation_code) === null || _state$occupation_cod === void 0 ? void 0 : _state$occupation_cod.value, employmentStatus: state.employment_status }; }
export function employmentStatusPageReverseMapper(state) {
    var party = state.geQuoteParties.find(function (i) { return i.partyFlag == "1"; });
    if (!party || !party.employmentStatus)
        return null; //QBR-1527 issue 1
    if (party.employmentStatus === "1") {
        if (party.industryCode == null || party.jobPosition == null) {
            return null;
        }
    }
    else if (party.employmentStatus === "2") {
        if (party.industryCode == null || party.jobPosition == null || party.occupationCode == null) {
            return null;
        }
    }
    return { employment_status: party.employmentStatus, industry: party.industryCode ? { text: null, value: party.industryCode } : null, job_position: party.jobPosition ? { text: null, value: party.jobPosition } : null, occupation_code: party.occupationCode ? { text: null, value: party.occupationCode } : null };
}
export function saveOcpInfo2Mapper(state, fullFormState, store) { var _state$job_position2, _state$industry2, _state$occupation_cod2; return { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, partyFlag: "1", employmentStatus: fullFormState === null || fullFormState === void 0 ? void 0 : fullFormState.employment_status.employment_status, jobPosition: (_state$job_position2 = state.job_position) === null || _state$job_position2 === void 0 ? void 0 : _state$job_position2.value, industryCode: (_state$industry2 = state.industry) === null || _state$industry2 === void 0 ? void 0 : _state$industry2.value, occupationCode: (_state$occupation_cod2 = state.occupation_code) === null || _state$occupation_cod2 === void 0 ? void 0 : _state$occupation_cod2.value }; }
export function jobPageReverseMapper(state) {
    var party = state.geQuoteParties.find(function (i) { return i.partyFlag == "1"; });
    if (party == null)
        return null; //QBR-1527 issue 1
    if (party.employmentStatus !== "1" && party.employmentStatus !== "2")
        return null;
    if (party.industryCode == null && party.jobPosition == null)
        return null;
    if ((party.employmentStatus == "1" || party.employmentStatus == "2") && (party.industryCode == null || party.occupationCode == null))
        return null;
    return { industry: party.industryCode ? { text: null, value: party.industryCode } : null, job_position: party.jobPosition ? { text: null, value: party.jobPosition } : null, occupation_code: party.occupationCode ? { text: null, value: party.occupationCode } : null };
}
export function saveEduInfoMapper(state, fullFormState, store) { var defaultData = { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, educationList: [{ partyFlag: "1" }] }; return _merge(defaultData, { educationList: [{ education: state.education }] }); }
export function educationPageReverseMapper(state) { var _state$geQuoteParties5; var education = (_state$geQuoteParties5 = state.geQuoteParties.find(function (i) { return i.partyFlag == "1"; })) === null || _state$geQuoteParties5 === void 0 ? void 0 : _state$geQuoteParties5.education; if (!education) {
    return null;
} return { education: education }; }
export function saveFinInfo1Mapper(state, fullFormState, store) {
    var _state$income$prefill, _state$assets$prefill;
    var defaultData = { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid,
        uwQuestionList: [{ quetioncode: "8", answertype: "03" }, { quetioncode: "9", answertype: "03" }] };
    return _merge(defaultData, { uwQuestionList: [{ selectoption: state.income.value, answertext: "" + ((_state$income$prefill = state.income.prefill) !== null && _state$income$prefill !== void 0 ? _state$income$prefill : state.income_other) }, { selectoption: state.assets.value, answertext: "" + ((_state$assets$prefill = state.assets.prefill) !== null && _state$assets$prefill !== void 0 ? _state$assets$prefill : state.assets_other) }] });
}
export function incomePageReverseMapper(state) { var _state$geQuoteUwquest, _state$geQuoteUwquest2; var income = state === null || state === void 0 ? void 0 : (_state$geQuoteUwquest = state.geQuoteUwquestionList) === null || _state$geQuoteUwquest === void 0 ? void 0 : _state$geQuoteUwquest.find(function (i) { return i.quetioncode == "8"; }); var assets = state === null || state === void 0 ? void 0 : (_state$geQuoteUwquest2 = state.geQuoteUwquestionList) === null || _state$geQuoteUwquest2 === void 0 ? void 0 : _state$geQuoteUwquest2.find(function (i) { return i.quetioncode == "9"; }); if (state.geQuoteMain.isAssetShow === "Y" && (!income || !assets)) {
    return null;
} if (!income || !income.selectoption || !assets || !assets.selectoption) {
    return null;
} return { income: { value: income.selectoption }, income_other: income.answertext, assets: { value: assets.selectoption }, assets_other: assets.answertext }; }
export function saveFinInfo2Mapper(state, fullFormState, store) {
    var f1State = [];
    if (fullFormState !== null && fullFormState !== void 0 && fullFormState.income) {
        f1State.push.apply(f1State, __spreadArray([], __read(saveFinInfo1Mapper(fullFormState === null || fullFormState === void 0 ? void 0 : fullFormState.income).uwQuestionList), false));
    }
    var sourcesOfFundsItemDefault = { quetioncode: "10", answertype: "03", answertext: "" };
    return { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid,
        uwQuestionList: __spreadArray(__spreadArray([], __read(f1State), false), __read(Array.from(new Set(state.sources_of_funds)).map(function (sof) { return Object.assign({}, sourcesOfFundsItemDefault, { selectoption: sof }); })), false) };
}
export function sourcesOfFundsPageReverseMapper(state) { var _state$geQuoteUwquest3; var sofList = state === null || state === void 0 ? void 0 : (_state$geQuoteUwquest3 = state.geQuoteUwquestionList) === null || _state$geQuoteUwquest3 === void 0 ? void 0 : _state$geQuoteUwquest3.filter(function (i) { return i.quetioncode == "10"; }); if (!sofList || sofList.length == 0) {
    return null;
} return { sources_of_funds: __spreadArray([], __read(sofList.map(function (i) { var _i$selectoption; return (_i$selectoption = i.selectoption) !== null && _i$selectoption !== void 0 ? _i$selectoption : ""; })), false) }; }
export function saveTaxInfoMapper(state, fullFormState, store) { var _state$country_of_bir, _state$country_of_bir2; var taxQuestionDefault = { rontInner: "", rontOuter: "" }; var party = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteParties.find(function (e) { return e.partyFlag === "1"; }); var taxReq = { isHKTax: state.hk_tax === "Y", pobCountryCode: (_state$country_of_bir = (_state$country_of_bir2 = state.country_of_birth) === null || _state$country_of_bir2 === void 0 ? void 0 : _state$country_of_bir2.value) !== null && _state$country_of_bir !== void 0 ? _state$country_of_bir : party === null || party === void 0 ? void 0 : party.pobCountryCode, geQuoteCrsInfo: [], productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid }; var otherTaxList = __spreadArray([], __read(state.tax_question.map(function (t, i) { var _t$tax_country_code, _t$tax_question_optio, _t$tax_tincode, _t$tax_reason; return Object.assign({}, taxQuestionDefault, { groupCode: "" + (i + 1), countryCode: t === null || t === void 0 ? void 0 : (_t$tax_country_code = t.tax_country_code) === null || _t$tax_country_code === void 0 ? void 0 : _t$tax_country_code.value, taxQuestionOption: t === null || t === void 0 ? void 0 : (_t$tax_question_optio = t.tax_question_option) === null || _t$tax_question_optio === void 0 ? void 0 : _t$tax_question_optio.value, tinCode: (_t$tax_tincode = t === null || t === void 0 ? void 0 : t.tax_tincode) !== null && _t$tax_tincode !== void 0 ? _t$tax_tincode : "", reason: (_t$tax_reason = t === null || t === void 0 ? void 0 : t.tax_reason) !== null && _t$tax_reason !== void 0 ? _t$tax_reason : "" }); })), false); return state.other_tax === "Y" ? _merge(taxReq, { geQuoteCrsInfo: otherTaxList }) : taxReq; }
export function taxPageReverseMapper(state, store) {
    var _state$geQuoteParties6, _store$qnbProduct$pro, _store$qnbProduct$pro2, _store$qnbProduct$pro3, _store$qnbProduct$pro4, _store$qnbProduct$pro5, _sort$map, _ref;
    if (state.geQuoteMain.isPrefilled === "N" && state.geQuoteMain.isHkTax === null) {
        return null;
    }
    var geQuoteCrsInfoListOriginalValue = state.geQuoteCrsInfoList;
    var taxQuestions = state.geQuoteCrsInfoList;
    var party = state === null || state === void 0 ? void 0 : (_state$geQuoteParties6 = state.geQuoteParties) === null || _state$geQuoteParties6 === void 0 ? void 0 : _state$geQuoteParties6.find(function (p) { return p.partyFlag === "1"; });
    if (store !== null && store !== void 0 && (_store$qnbProduct$pro = store.qnbProduct.productConfig) !== null && _store$qnbProduct$pro !== void 0 && (_store$qnbProduct$pro2 = _store$qnbProduct$pro.productConfig) !== null && _store$qnbProduct$pro2 !== void 0 && (_store$qnbProduct$pro3 = _store$qnbProduct$pro2.step2) !== null && _store$qnbProduct$pro3 !== void 0 && (_store$qnbProduct$pro4 = _store$qnbProduct$pro3.pages.find(function (page) { return page.pageId === "tax"; })) !== null && _store$qnbProduct$pro4 !== void 0 && (_store$qnbProduct$pro5 = _store$qnbProduct$pro4.inputId) !== null && _store$qnbProduct$pro5 !== void 0 && _store$qnbProduct$pro5.find(function (input) { return input === "country_of_birth"; }))
        if (!(party !== null && party !== void 0 && party.pobCountryCode)) {
            return null;
        }
    if (!taxQuestions || taxQuestions.length === 0) {
        var _party$pobCountryCode;
        return { country_of_birth: { value: (_party$pobCountryCode = party === null || party === void 0 ? void 0 : party.pobCountryCode) !== null && _party$pobCountryCode !== void 0 ? _party$pobCountryCode : "" }, hk_tax: state.geQuoteMain.isHkTax, other_tax: !taxQuestions ? undefined : taxQuestions.length > 0 ? "Y" : "N",
            tax_question: [] };
    }
    var taxQuestionMapped = (_sort$map = (_ref = __spreadArray([], __read(taxQuestions), false)) === null || _ref === void 0 ? void 0 : _ref.sort(function (a, b) { return Number(a.taxQuestionOption) - Number(b.taxQuestionOption); }).map(function (q) { var _q$tinCode; return { tax_tincode: (_q$tinCode = q.tinCode) !== null && _q$tinCode !== void 0 ? _q$tinCode : "", tax_reason: q.reason, tax_question_option: q.taxQuestionOption ? { value: q.taxQuestionOption } : undefined, tax_country_code: q.countryCode ? { value: q.countryCode } : undefined }; })) !== null && _sort$map !== void 0 ? _sort$map : [];
    if (taxQuestionMapped.length === 0) {
        taxQuestionMapped.push({ tax_tincode: "", tax_reason: "", tax_question_option: undefined, tax_country_code: undefined });
    }
    if (geQuoteCrsInfoListOriginalValue != null) {
        var _party$pobCountryCode2;
        return { country_of_birth: { value: (_party$pobCountryCode2 = party === null || party === void 0 ? void 0 : party.pobCountryCode) !== null && _party$pobCountryCode2 !== void 0 ? _party$pobCountryCode2 : "" }, hk_tax: state.geQuoteMain.isHkTax, other_tax: !taxQuestions ? undefined : taxQuestions.length > 0 ? "Y" : "N",
            tax_question: __spreadArray([], __read(taxQuestionMapped), false) };
    }
    return { country_of_birth: party !== null && party !== void 0 && party.pobCountryCode ? { value: party.pobCountryCode } : undefined, hk_tax: state.geQuoteMain.isHkTax, other_tax: !taxQuestions ? undefined : taxQuestions.length > 0 ? "Y" : "N",
        tax_question: __spreadArray([], __read(taxQuestionMapped), false) };
}
export function saveAddrInfoMapper(state, fullFormState, store) {
    var _state$national, _state$region, _state$district, _state$address_line_, _state$address_line_2;
    var defaultData = { addressList: [{ partyFlag: "1", sameToMailAddr: "Y" }] };
    return _merge(defaultData, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, addressList: [{ nationality: (_state$national = state.national) === null || _state$national === void 0 ? void 0 : _state$national.value,
                //QBR-1527
                permAddrCountryCode: "HK", territoryCode: (_state$region = state.region) === null || _state$region === void 0 ? void 0 : _state$region.value, districtCode: (_state$district = state.district) === null || _state$district === void 0 ? void 0 : _state$district.value, mailAddr01: state.address_line_1, mailAddr02: (_state$address_line_ = state.address_line_2) !== null && _state$address_line_ !== void 0 ? _state$address_line_ : "", mailAddr03: (_state$address_line_2 = state.address_line_3) !== null && _state$address_line_2 !== void 0 ? _state$address_line_2 : "" }] });
}
export function addressPageReverseMapper(state) {
    var _party$nationality, _party$territoryCode, _party$districtCode;
    var party = state.geQuoteParties.find(function (p) { return p.partyFlag === "1"; });
    if (!party)
        return null;
    if (!party.mailAddr01)
        return null;
    return { national: { value: (_party$nationality = party.nationality) !== null && _party$nationality !== void 0 ? _party$nationality : "" },
        city: { value: "HK" // value: party.pobCountryCode ?? "HK",
        }, region: { value: (_party$territoryCode = party.territoryCode) !== null && _party$territoryCode !== void 0 ? _party$territoryCode : "" }, district: { value: (_party$districtCode = party.districtCode) !== null && _party$districtCode !== void 0 ? _party$districtCode : "" }, address_line_1: party.mailAddr01, address_line_2: party.mailAddr02, address_line_3: party.mailAddr03 };
}
export function saveOcrInfoMapper(state, fullFormState, store) { var _state$gender; var defaultData = { partyFlag: "1" }; return _merge(defaultData, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, birthday: moment(state.date_of_birth).format(), partyCName: state.chinese_name, lastNameEn: state.surname, firstNameEn: state.given_name, gender: (_state$gender = state.gender) === null || _state$gender === void 0 ? void 0 : _state$gender.value, identifyNumber: state.hkid }); }
export function ekycApplInput2PageReverseMapper(state) { var defaultValue = { chinese_name: "", given_name: "", surname: "", gender: null, date_of_birth: "", hkid: "" }; var appl = state.geQuoteParties.find(function (p) { return p.partyFlag === "1"; }); if (!appl)
    return null; if (!appl.identifyNumber)
    return null; return _merge(defaultValue, { chinese_name: appl.partyCName, given_name: appl.firstNameEn, surname: appl.lastNameEn, gender: { value: appl.gender }, date_of_birth: moment(appl.birthday).toDate(), hkid: appl.identifyNumber }); }
export function savePartyOcrInfoMapper(state, fullFormState, store) { var _state$gender2; var defaultData = { partyFlag: "2" }; return _merge(defaultData, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, birthday: moment(state.date_of_birth).format(), partyCName: state.chinese_name, lastNameEn: state.surname, firstNameEn: state.given_name, gender: (_state$gender2 = state.gender) === null || _state$gender2 === void 0 ? void 0 : _state$gender2.value, identifyNumber: state.hkid, state: "EK4_LA" }); }
export function ekycPartyInput2PageReverseMapper(state) { var defaultValue = { chinese_name: "", given_name: "", surname: "", gender: null, date_of_birth: "", hkid: "" }; var insParty = state.geQuoteParties.find(function (p) { return p.partyFlag === "2"; }); if (!insParty)
    return null; if (!insParty.identifyNumber)
    return null; return _merge(defaultValue, { chinese_name: insParty.partyCName, given_name: insParty.firstNameEn, surname: insParty.lastNameEn, gender: { value: insParty.gender }, date_of_birth: moment(insParty.birthday).toDate(), hkid: insParty.identifyNumber }); }
export function nonCreditCardPaymentConfirmChequeMapper(state, fullFormState, store) { var _quoteInfo$policyNo, _quoteInfo$proposalSi; var defaultValue = { useSubmitTime: true, paymethod: "Q", successIndicator: "", cardHolder: "", idcardNum: "", idcardType: "", currency: "", userId: "", cardHolderFirstName: "", cardHolderLastName: "", cardNumber: "", senderBankId: "", branchCode: "", jointAccountName: "", bankTransferFlag: "", accountNumber: "", billAccNum: "", accountName: "", quoteNo: store === null || store === void 0 ? void 0 : store.qnb.quotationNumber }; var quoteInfo = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo; var exchangeRateObj = store === null || store === void 0 ? void 0 : store.qnbProduct.exchangeRate; var policyNo = (_quoteInfo$policyNo = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.policyNo) !== null && _quoteInfo$policyNo !== void 0 ? _quoteInfo$policyNo : ""; var proposalSId = (_quoteInfo$proposalSi = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.proposalSid) !== null && _quoteInfo$proposalSi !== void 0 ? _quoteInfo$proposalSi : ""; var exchangeRate = "" + (exchangeRateObj === null || exchangeRateObj === void 0 ? void 0 : exchangeRateObj.exchangeRate); return _merge(defaultValue, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, policyNo: policyNo, proposalSId: proposalSId, exchangeRate: exchangeRate }); }
export function nonCreditCardPaymentConfirmFpsMapper(state, fullFormState, store) { var _quoteInfo$policyNo2, _quoteInfo$proposalSi2; var defaultValue = { useSubmitTime: true, paymethod: "F", currency: "13", successIndicator: "", cardHolder: "", idcardNum: "", idcardType: "", userId: "", cardHolderFirstName: "", cardHolderLastName: "", cardNumber: "", jointAccountName: "", bankTransferFlag: "", billAccNum: "", quoteNo: store === null || store === void 0 ? void 0 : store.qnb.quotationNumber }; var quoteInfo = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo; var exchangeRateObj = store === null || store === void 0 ? void 0 : store.qnbProduct.exchangeRate; var policyNo = (_quoteInfo$policyNo2 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.policyNo) !== null && _quoteInfo$policyNo2 !== void 0 ? _quoteInfo$policyNo2 : ""; var proposalSId = (_quoteInfo$proposalSi2 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.proposalSid) !== null && _quoteInfo$proposalSi2 !== void 0 ? _quoteInfo$proposalSi2 : ""; var exchangeRate = "" + (exchangeRateObj === null || exchangeRateObj === void 0 ? void 0 : exchangeRateObj.exchangeRate); return _merge(defaultValue, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, policyNo: policyNo, proposalSId: proposalSId, exchangeRate: exchangeRate }); }
export function nonCreditCardPaymentConfirmBankMapper(state, fullFormState, store) { var _quoteInfo$policyNo3, _quoteInfo$proposalSi3; var defaultValue = { useSubmitTime: true, paymethod: "F", currency: "13", successIndicator: "", cardHolder: "", idcardNum: "", idcardType: "", userId: "", cardHolderFirstName: "", cardHolderLastName: "", cardNumber: "", jointAccountName: "", bankTransferFlag: "", billAccNum: "", quoteNo: store === null || store === void 0 ? void 0 : store.qnb.quotationNumber }; var quoteInfo = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo; var exchangeRateObj = store === null || store === void 0 ? void 0 : store.qnbProduct.exchangeRate; var policyNo = (_quoteInfo$policyNo3 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.policyNo) !== null && _quoteInfo$policyNo3 !== void 0 ? _quoteInfo$policyNo3 : ""; var proposalSId = (_quoteInfo$proposalSi3 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.proposalSid) !== null && _quoteInfo$proposalSi3 !== void 0 ? _quoteInfo$proposalSi3 : ""; var exchangeRate = "" + (exchangeRateObj === null || exchangeRateObj === void 0 ? void 0 : exchangeRateObj.exchangeRate); return _merge(defaultValue, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, accountNumber: state.account_number, accountName: state.account_name, branchCode: state.branch_code, senderBankId: state.sender_bank_id.value, policyNo: policyNo, proposalSId: proposalSId, exchangeRate: exchangeRate }); }
export function nonCreditCardPaymentConfirmHsbcMapper(state, fullFormState, store) { var _quoteInfo$policyNo4, _quoteInfo$proposalSi4; var defaultValue = { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, useSubmitTime: true, currency: "13", successIndicator: "", cardHolder: "", idcardNum: "", idcardType: "", userId: "", cardHolderFirstName: "", cardHolderLastName: "", cardNumber: "", jointAccountName: "", bankTransferFlag: "", accountNumber: "", accountName: "", branchCode: "", senderBankId: "", quoteNo: store === null || store === void 0 ? void 0 : store.qnb.quotationNumber }; var quoteInfo = store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo; var exchangeRateObj = store === null || store === void 0 ? void 0 : store.qnbProduct.exchangeRate; var paymentInfo = store === null || store === void 0 ? void 0 : store.qnbProduct.paymentInfo; var policyNo = (_quoteInfo$policyNo4 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.policyNo) !== null && _quoteInfo$policyNo4 !== void 0 ? _quoteInfo$policyNo4 : ""; var proposalSId = (_quoteInfo$proposalSi4 = quoteInfo === null || quoteInfo === void 0 ? void 0 : quoteInfo.proposalSid) !== null && _quoteInfo$proposalSi4 !== void 0 ? _quoteInfo$proposalSi4 : ""; var exchangeRate = "" + (exchangeRateObj === null || exchangeRateObj === void 0 ? void 0 : exchangeRateObj.exchangeRate); return _merge(defaultValue, { paymethod: state.hsbcPaymentMethod, billAccNum: paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.billAccNum, policyNo: policyNo, proposalSId: proposalSId, exchangeRate: exchangeRate }); }
export function contactPageReverseMapper(state) { var party = state.geQuoteParties.find(function (i) { return i.partyFlag === "1"; }); if (!party)
    return null; if (party.email == null)
    return null; if (party.mobilePhone == null)
    return null; return { email: party.email, mobile: party.mobilePhone }; } /* party 2 mapper start */
export function saveOcpInfo1PartyMapper(state, fullFormState, store) { var _state$job_position3, _state$industry3, _state$occupation_cod3; return { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, partyFlag: "2", jobPosition: (_state$job_position3 = state.job_position) === null || _state$job_position3 === void 0 ? void 0 : _state$job_position3.value, industryCode: (_state$industry3 = state.industry) === null || _state$industry3 === void 0 ? void 0 : _state$industry3.value, occupationCode: (_state$occupation_cod3 = state.occupation_code) === null || _state$occupation_cod3 === void 0 ? void 0 : _state$occupation_cod3.value, employmentStatus: state.employment_status, state: "AP1_LA" }; }
export function employmentStatusPartyPageReverseMapper(state) {
    var party = state.geQuoteParties.find(function (i) { return i.partyFlag === "2"; });
    if (!party || !party.employmentStatus)
        return null; //QBR-1527 issue 1
    if (party.employmentStatus === "1") {
        if (party.industryCode == null || party.jobPosition == null) {
            return null;
        }
    }
    else if (party.employmentStatus === "2") {
        if (party.industryCode == null || party.jobPosition == null || party.occupationCode == null) {
            return null;
        }
    }
    return { employment_status: party.employmentStatus, industry: party.industryCode ? { text: null, value: party.industryCode } : null, job_position: party.jobPosition ? { text: null, value: party.jobPosition } : null, occupation_code: party.occupationCode ? { text: null, value: party.occupationCode } : null };
}
export function saveOcpInfo2PartyMapper(state, fullFormState, store) { var _state$job_position4, _state$industry4, _state$occupation_cod4; return { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, partyFlag: "2", employmentStatus: fullFormState === null || fullFormState === void 0 ? void 0 : fullFormState.employment_status.employment_status, jobPosition: (_state$job_position4 = state.job_position) === null || _state$job_position4 === void 0 ? void 0 : _state$job_position4.value, industryCode: (_state$industry4 = state.industry) === null || _state$industry4 === void 0 ? void 0 : _state$industry4.value, occupationCode: (_state$occupation_cod4 = state.occupation_code) === null || _state$occupation_cod4 === void 0 ? void 0 : _state$occupation_cod4.value }; }
export function jobPartyPageReverseMapper(state) {
    var party = state.geQuoteParties.find(function (i) { return i.partyFlag === "2"; });
    if (party == null)
        return null; //QBR-1527 issue 1
    if (party.employmentStatus !== "1" && party.employmentStatus !== "2")
        return null;
    if (party.industryCode == null && party.jobPosition == null)
        return null;
    if ((party.employmentStatus == "1" || party.employmentStatus == "2") && (party.industryCode == null || party.occupationCode == null))
        return null;
    return { industry: party.industryCode ? { text: null, value: party.industryCode } : null, job_position: party.jobPosition ? { text: null, value: party.jobPosition } : null, occupation_code: party.occupationCode ? { text: null, value: party.occupationCode } : null };
}
export function saveAddrInfoPartyMapper(state, //當前頁面數據
fullFormState, store) {
    var _state$national2, _state$region2, _state$district2, _state$address_line_3, _state$address_line_4;
    var defaultData = { addressList: [{ partyFlag: "2", sameToMailAddr: state.isSameAddress ? "Y" : "N" //Y是與投保人相同地址，N是新填寫的受保人地址
            }] };
    return _merge(defaultData, { productCode: store === null || store === void 0 ? void 0 : store.qnbProduct.quoteInfo.geQuoteMain.eid, addressList: [{ nationality: (_state$national2 = state.national) === null || _state$national2 === void 0 ? void 0 : _state$national2.value, permAddrCountryCode: "HK", territoryCode: (_state$region2 = state.region) === null || _state$region2 === void 0 ? void 0 : _state$region2.value, districtCode: (_state$district2 = state.district) === null || _state$district2 === void 0 ? void 0 : _state$district2.value, mailAddr01: state.address_line_1, mailAddr02: (_state$address_line_3 = state.address_line_2) !== null && _state$address_line_3 !== void 0 ? _state$address_line_3 : "", mailAddr03: (_state$address_line_4 = state.address_line_3) !== null && _state$address_line_4 !== void 0 ? _state$address_line_4 : "" }], state: "AP7_LA" });
}
export function addressPartyPageReverseMapper(state) { var _party$nationality2, _party$territoryCode2, _party$districtCode2; var party = state.geQuoteParties.find(function (p) { return p.partyFlag === "2"; }); if (!party)
    return null; if (!party.mailAddr01)
    return null; return { national: { value: (_party$nationality2 = party.nationality) !== null && _party$nationality2 !== void 0 ? _party$nationality2 : "" }, city: { value: "HK" }, region: { value: (_party$territoryCode2 = party.territoryCode) !== null && _party$territoryCode2 !== void 0 ? _party$territoryCode2 : "" }, district: { value: (_party$districtCode2 = party.districtCode) !== null && _party$districtCode2 !== void 0 ? _party$districtCode2 : "" }, address_line_1: party.mailAddr01, address_line_2: party.mailAddr02, address_line_3: party.mailAddr03 }; } /* party 2 mapper end */
